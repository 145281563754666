import { CertemyEnvironment } from './environment.interface';
import pjson from '../../package.json';

export const environment: CertemyEnvironment = {
  name: 'UAT',
  release: pjson.version,
  production: true,
  mixpanelToken: '7ebbe6200a7b0145381ac73119d78c73',
  stripeKey: 'pk_test_NMuH6XKWKXmgrBkN9BZx0LSJ',
  sentryKey: 'https://63b904571b5f45ab98dc3e87659e46f8@sentry-monitoring.certemy.com/16',
  port: '443',
  portAuthz: '443',
  JWT_EXPIRE_TIME: 120,
  CERTEMY_WEBSITE_URL: 'https://certemy.com',
  enableMFA: false,
  GIT_COMMIT: '8a35d269405f8a84265be28b794221b362d15e08',
  PAYFLOW_URL: 'https://pilot-payflowlink.paypal.com',
  SQUARE_URL: 'https://sandbox.web.squarecdn.com/v1/square.js',
  REFERRAL_URL: 'https://certemy-tracking-qa.surge.sh/scripts/tracking/referral.v1.js',
  useReferral: false,
  exactPaymentPath: 'https://rpm.demo.e-xact.com/payment',
  ePayPaymentPath: 'https://epay2dev.wvsto.com/ePayCustomPage/ePay.aspx',
  showExternalLogin: true,
  googleAuthClientId: '14911028123-jc8dr1mot96ofaohd58jjkgmgigbhq6n.apps.googleusercontent.com',
};
